import styles from './Nav.module.css';

export default function Nav() {
  return (
    <nav className={styles.nav}>
      <ul className={styles.links}>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li className={styles.hide}>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </nav>
  );
};
