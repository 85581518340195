import styles from './Footer.module.css';

const emailAddress = 'sunny@kaiserservices.ca';

export default function Footer() {

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footerleft}>
          <h1>Let's work together.</h1>
          <p>Ready to get started? Contact us today and find out how our expertise can help your business grow.</p>
        </div>
        <div className={styles.footerright}>
          <h1>Get in touch.</h1>
          <p>1234 Main Street</p>
          <p>Anytown, USA 12345</p>
          <p>
            <a href="tel:555-555-5555">555-555-5555</a>
          </p>
          <p>
            <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          </p>
        </div>
        <div className={styles.footerbottom}>
          <p>
            &copy; Copyright Kaiser Consulting
            <br />
            <span className={styles.hide}> | </span>
            Website by <a href="http://sydsis.co" >Sydney Sisco.</a>
          </p>
        </div>
      </div>
    </footer>
  );
};
