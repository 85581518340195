import React, { useState, useEffect } from 'react';
import styles from './ContactCTA.module.css';

export default function ContactCTA() {

  const [buttonStyle, setButtonStyle] = useState(styles.float);
  const handleScroll = () => {
      const position = window.pageYOffset;
      if (position < 4000) {
        setButtonStyle(styles.float);
      } else {
        setButtonStyle(styles.hide);
      }
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <a href="#contact" class={buttonStyle}>
      <p class={styles.myfloat}>Contact</p>
    </a>
  );
}
