import styles from './Services.module.css';
import hospital from '../hospital-purple.png';
import earth from '../earth-purple.png';
import plane from '../plane-purple.png';

export default function Services() {
  return (
    <div className={styles.services}>
      <a className={styles.anchor} id="services"></a>
      <h1>Services you can rely on <br/>with results you can see.</h1>
      {/* <h1>Services</h1> */}
      <div className={styles.container}>
        <div className={styles.iconblock}>
          <img src={hospital}></img>
          <h3>Healthcare Consulting</h3>
          <p>Solutions and support for small to large-sized organizations.</p>
        </div>
        <div className={styles.iconblock}>
          <img src={earth}></img>
          <h3>Global Presence</h3>
          <p>International consulting and support for healthcare organizations.</p>
        </div>
        <div className={styles.iconblock}>
          <img src={plane}></img>
          <h3>On Premises Services</h3>
          <p>Services available in-person or remotely.</p>
        </div>
      </div>
    </div>
  );
};
