import logo from '../logo.png';
import styles from './Header.module.css';

import Nav from './Nav';

export default function Header() {
  
  return (
    <header className={styles.header}>
      <a href="#"><img className={styles.logo} src={logo} alt="logo" /></a>
      <Nav />
    </header>
  );
};
