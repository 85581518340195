import './App.css';
import styles from './App.module.css';
import Header from './components/Header';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import ContactCTA from './components/ContactCTA';
import Footer from './components/Footer';


function App() {
  return (
    <div class="wrapper"> 

      <Header />
  
      <div class="fixed-bg bg-1">
        <h1 className={styles.floating}>Consulting with a <span className={styles.highlight}>personal</span> touch</h1>
      </div>
      
      <Services />
      {/* <div class="scroll-bg">
        <h2>Services</h2>
        <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in dolor tempor, posuere mi id, eleifend magna. Nulla at lectus magna. Etiam sodales arcu at lectus porttitor accumsan. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur id urna dolor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin et pharetra justo, quis suscipit risus. Maecenas tincidunt efficitur ex in pharetra.</h3>
      </div> */}
      
      <div class="fixed-bg bg-2">
        <h1 className={styles.floating}>Solutions to <span className={styles.highlight}>your</span><br/> business problems</h1>
      </div>
      
      <About />
      {/* <div class="scroll-bg">
        <a className={styles.anchor} id="about"></a>
        <h2>About</h2>
        <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in dolor tempor, posuere mi id, eleifend magna. Nulla at lectus magna.</h3>
      </div> */}

      <div class="fixed-bg bg-3">
        <h1 className={styles.floating}>Let's talk <span className={styles.highlight} >business</span></h1>
      </div>

      <Contact />
      {/* <div id="contact" class="scroll-bg">
        <h2>Contact</h2>
        <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in dolor tempor, posuere mi id, eleifend magna. Nulla at lectus magna.</h3>
      </div> */}

      <ContactCTA />

      <Footer />

    </div>
  );
}

export default App;
