import styles from './Contact.module.css';
import email from '../email.svg';
import telegram from '../telegram.svg';

const emailAddress = 'sunny@kaiserservices.ca';
const telegramAddress = 'kaiserConsulting';

export default function Contact() {
  return (
    <section className={styles.contact} >
      <a className={styles.anchor} id="contact"></a>
      <h2>Contact</h2>
      <h3>If you're looking for professional healthcare consulting, you've come to the right place. We can help, contact us now.</h3>
      <div className={styles.contactinfo}>
        <img src={email} className={styles.icon}/>
        <br />
        <a href={`mailto:${emailAddress}`} className={styles.email}>{emailAddress}</a>
        <br />
        <img src={telegram} className={styles.icon}/>
        <br />
        <a href={`https://t.me/${telegramAddress}`} className={styles.telegram}>@{telegramAddress}</a>
      </div>
    </section>
  );
};
